import request from '@/util/request'

// 保存分类
export function saveCate (data) {
    return request({
        url: '/admin/v1/creativeCollege/saveCate',
        method: 'POST',
        data
    })
}

// 获取分类列表
export function cateList (params) {
    return request({
        url: '/admin/v1/creativeCollege/cateList',
        method: 'GET',
        params
    })
}

// 删除分类
export function delCate (params) {
    return request({
        url: '/admin/v1/creativeCollege/delCate',
        method: 'DELETE',
        params
    })
}


// 获取文章列表
export function articleList (params) {
    return request({
        url: '/admin/v1/creativeCollege/articleList',
        method: 'GET',
        params
    })
}

// 上下架文章
export function changeArticleStatus (data) {
    return request({
        url: '/admin/v1/creativeCollege/changeArticleStatus',
        method: 'POST',
        data
    })
}

// 删除文章
export function delArticle (params) {
    return request({
        url: '/admin/v1/creativeCollege/delArticle',
        method: 'DELETE',
        params
    })
}

// 保存文章
export function saveArticle (data) {
    return request({
        url: '/admin/v1/creativeCollege/saveArticle',
        method: 'POST',
        data
    })
}

// 获取文章详情
export function article (params) {
    return request({
        url: '/admin/v1/creativeCollege/article',
        method: 'GET',
        params
    })
}

// 获取首页banner设置
export function indexBanner (params) {
    return request({
        url: '/admin/v1/creativeCollege/indexBanner',
        method: 'GET',
        params
    })
}

// 设置首页banner
export function setIndexBanner (data) {
    return request({
        url: '/admin/v1/creativeCollege/setIndexBanner',
        method: 'POST',
        data
    })
}

// 删除某个banner
export function delBanner (params) {
    return request({
        url: '/admin/v1/creativeCollege/delBanner',
        method: 'DELETE',
        params
    })
}

//  获取场景分类
export function sceneCate (params) {
    return request({
        url: '/admin/v1/creativeCollege/sceneCate',
        method: 'GET',
        params
    })
}

//  设置首页场景分类文章列表
export function saveSceneArticle (data) {
    return request({
        url: '/admin/v1/creativeCollege/saveSceneArticle',
        method: 'POST',
        data
    })
}

//  获取首页场景分类下文章
export function getIndexSceneCateArticle (params) {
    return request({
        url: '/admin/v1/creativeCollege/getIndexSceneCateArticle',
        method: 'GET',
        params
    })
}