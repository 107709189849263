<template>
	<div class="dialog">
		<el-dialog :title="typeFlag=='add'?'添加':'修改'" :visible.sync="dialogVisible" width="840px" :before-close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="文章分类" prop="cate_id">
					<el-cascader
						size="small" clearable
						v-model="ruleForm.cate_article_id"
						:options="cateArticleList"
						:props="props"
						:show-all-levels="false"
						@change="cateArticleChange"
						></el-cascader>
				</el-form-item>
				<el-form-item label="场景分类" prop="cate_id">
					<el-cascader
						size="small" clearable
						v-model="ruleForm.cate_scene_id"
						:options="cateSceneList"
						:props="props"
						:show-all-levels="false"
						@change="cateSceneChange"
						></el-cascader>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input-number size="small" v-model="ruleForm.sort" :min="0"></el-input-number>
				</el-form-item>
				<el-form-item label="评论开关" prop="comment_switch">
					<el-switch v-model="ruleForm.comment_switch"></el-switch>
				</el-form-item>
				<el-form-item label="文章封面" prop="cover">
					<Qiniu :isCropper="true" :width="418" :height="335" :imgKey="ruleForm.cover" @uploadSuccess="uploadSuccess"></Qiniu>
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input size="small" v-model="ruleForm.title"></el-input>
				</el-form-item>
                <el-form-item label="文章内容" prop="content">
					<quill-editor
                        class="ql-editor"
                        ref="myTextEditor"
                        style="width:700px;height:500px"
                        :options="quillOption"
                        v-model="ruleForm.content"
                        ></quill-editor>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
import { saveArticle,cateList } from '@/api/college'
import Qiniu from '@/components/qiniu.vue'
// 以下是富文本编辑框
import { quillEditor } from 'vue-quill-editor'
import quill from 'quill'
import quillConfig from '@/components/quilleditor.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
	name: "ClassAdd",
	props: {
		dialogVisible: {
			type: Boolean,
			default: false
		},
		typeFlag: { // 判断是新建 编辑 详情
			type: String,
			default: 'add'
		},
		row: { // 判断是新建 编辑 详情
			type: Object,
			default: ()=>{
				return {}
			}
		},
	},
	data() {
		return {
			cateArticleList: [], // 文章分类列表
			cateSceneList: [], // 场景分类列表
			props: {
				value: 'id',
				label: 'cate_name',
				checkStrictly: true
			},
			ruleForm: {
				cate_article_id: '',
				cate_scene_id: '',
				cover: '',
				title: '',
				content: '',
				sort: '',
				comment_switch: false
			},
			rules: {
				// title: [
				// 	{ required: true, message: '请输入标题', trigger: 'blur' }
				// ],
			},
			// 以下是富文本编辑相关
			editor: null, // 富文本编辑器对象
			editorOption: { //  富文本编辑器配置
				placeholder: '请输入',
				theme: 'snow', // or 'bubble'
				modules: {
					toolbar: {
						container: '#toolbar'
					}
				}
			},
			quillOption: quillConfig // 富文本编辑器
		}
	},
	components: {
		quillEditor,
		Qiniu
	},
	watch: {
		dialogVisible: {
			handler() {
				Object.keys(this.ruleForm).forEach(item=> {
					if(item == 'comment_switch') {
						this.ruleForm[item] = this.row[item] ? true : false
					}else {
						this.ruleForm[item] = this.row[item]
					}
				})
			},
			immediate: true
		}
	},
	created() {
		this.defaultStyle()
	},
	methods: {
		// 富文本左右居中用style样式
		defaultStyle() {
			var Align = quill.import('attributors/style/align');
			Align.whitelist = ['right', 'center', 'justify'];
			quill.register(Align, true);
		},
		// 获取分类
		getallCate() {
			if(this.cateArticleList.length > 0 || this.cateSceneList.length > 0) return
			cateList().then((res) => {
				this.setCateList(res.data)
			}).catch((err) => {
				console.log(err)
			})
		},
		// 设置分类
		setCateList(catesList) {
			// 先根据父分类id排序， 让父分类元素都拍在前面
			let list = catesList.sort((a,b)=>a.parent_id-b.parent_id)
			let cateArticleList = [],  // 分类类型是1和3
				cateSceneList = [];  // 分类类型是2
			list.forEach(item=> {
				if ( (item.type==1 || item.type == 3 ) && item.parent_id == 0 ) { // 文章分类列表
					cateArticleList.push(item)
				} else if( item.type==2 && item.parent_id == 0 ) { // 场景分类列表
					cateSceneList.push(item)
				} else { // 子节点
					let articleI = cateArticleList.findIndex(article_item=>article_item.id == item.parent_id)
					let sceneI = cateSceneList.findIndex(scene_item=>scene_item.id == item.parent_id)
					if(articleI > -1){
						let child = cateArticleList[articleI].children
						cateArticleList[articleI].children = child ? child.concat([item]) : [item]
					}
					if(sceneI > -1){
						let child = cateSceneList[sceneI].children
						cateSceneList[sceneI].children = child ? child.concat([item]) : [item]
					}
				} 
			})
			this.cateArticleList = cateArticleList;
			this.cateSceneList = cateSceneList
		},
		// 选择分类
		cateArticleChange(val) {
			this.ruleForm.cate_article_id = val[val.length - 1]
		},
		cateSceneChange(val) {
			this.ruleForm.cate_scene_id = val[val.length - 1]
		},
		// 确定
		submitForm(formName){
			console.log(this.ruleForm, 111)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				}
			});
		},
		save(){
			let body = this.ruleForm
			this.typeFlag == 'edit' ? body.id = this.row.id : ''
			body.comment_switch = body.comment_switch ? 1 : 0
			saveArticle(body).then((res) => {
				if(res.code == 200){
					this.close()
					this.$emit('getList');
				}
			}).catch((err) => {
				console.log(err, 222)
			})
		},
		// 关闭
		close() {
			this.ruleForm = {
				cate_article_id: '',
				cate_scene_id: '',
				cover: '',
				title: '',
				content: '',
				sort: '',
				comment_switch: false
			}
			this.$emit('close');
		},
		// 选择图片成功
		uploadSuccess(e) {
			this.ruleForm.cover = this.$constant.qnUrl + '/' + e.key
		},
	}
};
</script>
<style lang="less">
.ql-container {
	height: calc(500px - 135px);
}
</style>
