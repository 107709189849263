<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
            <div>
				<el-input class="w140" size="small" placeholder="请输入标题搜索" v-model="search.title" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
            <div>
                <el-date-picker size="small" style="width: 230px;margin-right:10px" @change="searchList()"
                    v-model="search.time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <el-button size="small" type="primary" @click="add()">添加</el-button>
            </div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="created_at" label="日期">
				<template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="title" label="标题"></el-table-column>
			<el-table-column prop="cate_name" label="分类">
				<template slot-scope="scope">
					<span>{{ scope.row.cate_article_name }} {{ scope.row.cate_scene_name }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<span>{{ scope.row.status == 1 ? '上架' : '下架'}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="new_user_count" label="操作">
                <template slot-scope="scope">
                    <el-popconfirm
                        :title="`确定${scope.row.status == 1 ? '下架' : '上架'}吗？`"
                        @confirm="upArticle(scope.row)">
                        <el-link type="primary" slot="reference" style="margin-right: 10px;">{{ scope.row.status == 1 ? '下架' : '上架'}}</el-link>
                    </el-popconfirm>
                    <el-link type="primary" @click="editArticle(scope.row)" style="margin-right: 10px;">编辑</el-link>
					<el-popconfirm
                        title="确定删除吗？"
                        @confirm="deleteArticle(scope.row.id)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
				</template>
            </el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
		<ArticleAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :row="row" @getList="getList" @searchList="searchList" @close="close"></ArticleAdd>
	</div>
</template>
<script>
	import ArticleAdd from './compontent/articleAdd'
	import { articleList, changeArticleStatus, delArticle } from '@/api/college.js';
	export default {
		name: "Day",
		data() {
			return {
				loading: false,
				tableHeight: this.$constant.maxHeight,
				// 列表
				search: {
                    title: '',
					time: '',
				},
				// 列表
				total: 0,
				limit: 15,
				currentPage: 1,
				list: [],
				// 弹框
                typeFlag: 'add',
				dialogVisible: false,
				row: {}
			}
		},
		components: {
			ArticleAdd
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
            // 添加
            add() {
				this.$refs.add.getallCate()
				this.typeFlag = 'add'
				this.row = {}
				this.dialogVisible = true
            },
            // 编辑
            editArticle(row) {
				this.$refs.add.getallCate()
				this.typeFlag = 'edit'
				this.row = row
				this.dialogVisible = true
            },
            // 上下架
            upArticle(row) {
				changeArticleStatus({ 
					article_id: row.id,
					status: row.status == 1 ? 2 :1
				}).then((res) => {
					if(res.code == 200) {
						this.$message({ message: `${row.status == 1 ? '下架' : '上架'}成功`, type: 'success' });
						this.searchList()
					}
				}).catch((err) => {
					console.log(err, 222)
				})
            },
            // 删除
            deleteArticle(id) {
				delArticle({ article_id:id }).then((res) => {
					if(res.code == 200) {
						this.$message({ message: '删除成功', type: 'success' });
						this.searchList()
					}
				}).catch((err) => {
					console.log(err, 222)
				})
            },
			// 获取列表
			getList() {
				this.loading = true
				articleList({
					limit: this.limit,
					page: this.currentPage,
					title: this.search.title || null,
					start_time: this.$common.setSearchTime(this.search.time)[0],
					end_time: this.$common.setSearchTime(this.search.time)[1]
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err, 222)
				})
			},
			// 搜索
			searchList(){
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 关闭弹框
			close() {
				this.dialogVisible = false
			}
		}
	};
</script>
